import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import Layout from '../components/layout';

class PostTemplate extends Component {
  render() {
    const post = this.props.data.wordpressPost;

    return (
      <Layout>
        <Helmet>
          <title>{post.title}</title>
          <meta name="description" content={post.description} />
        </Helmet>
        <div id="main">
          <section id="one">
            <h1>
              <span dangerouslySetInnerHTML={{ __html: post.title }}></span>
            </h1>
            {post.date}
            <div dangerouslySetInnerHTML={{ __html: post.content }} />
          </section>
        </div>
      </Layout>
    );
  }
}

export default PostTemplate;

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPost(id: { eq: $id }) {
      title
      content
      date(formatString: "DD MMMM YYYY")
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
